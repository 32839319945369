// src/components/Footer/BoostyIcon.tsx
// Version: 1.3.0
// Description: Updated Boosty icon component with official logo and fixed colors, converted to TypeScript

import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const BoostyIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 715.8 317.4">
        <path
            fill="#FFFFFF"
            d="M632.8,138.6l-23.1,35.9l-2.3-35.9h-19.3h-6h-7.9c3.1-10.5,5.5-19,5.5-19l1.3-4.2h-25.5l-1.2,4.2l-5.5,19h-21.7c-3.6,0-5.4,0-5.4,0c-22.3,0-34.8,6.4-40,18.4c-3-11-12.9-18.4-28.2-18.4c-13.2,0-24.9,4.9-33.6,12.7c-4.5-7.8-13.4-12.7-25.8-12.7c-14.2,0-26.5,5.7-35.5,14.4c-4.1-8.7-13.4-14.4-26.7-14.4c-4.6,0-8.9,0.6-13,1.7l2-6.9c0-0.1,0.1-0.2,0.1-0.3l5.1-17.7h-25.4l-16.2,55.8c-0.4,1.1-0.8,2.2-1.1,3.4c-0.7,2.6-1.2,5.1-1.4,7.5c-2.1,15.6,4.7,27.3,23.6,28.1c1.7,0.2,3.5,0.3,5.5,0.3c13.4,0,25.9-5.7,35.1-14.4c3.9,8.7,13,14.4,27.1,14.4c12.5,0,24.3-4.9,33.2-12.7c4.3,7.8,13.1,12.7,26.2,12.7c28.4,0,49.6,0,63.6,0c19.7,0,30.8-3.9,36.6-12.3c0,8.1,4.6,12.3,16.2,12.3c9.4,0,22-2.1,39.3-6.3L563.3,240h25.4l69.5-101.4H632.8z M334.3,174.5c-2.4,8.2-10,14.8-17,14.8c-7,0-10.8-6.6-8.4-14.8c2.4-8.2,10-14.8,17-14.8C332.9,159.7,336.7,166.3,334.3,174.5z M396.4,174.5c-2.4,8.2-10,14.8-17,14.8s-10.8-6.6-8.4-14.8s10-14.8,17-14.8S398.8,166.3,396.4,174.5z M430.5,174.5c2.4-8.2,10-14.8,17-14.8s10.8,6.6,8.4,14.8c-2.3,8.1-9.8,14.6-16.7,14.8c-0.2,0-0.4,0-0.6,0C431.8,189.1,428.2,182.6,430.5,174.5z M512.2,186.7c-1.3,3-11.3,2.5-13.8,2.6c0,0-6.6,0-24.6,0c3.3-4.5,5.9-9.5,7.4-14.8c0.2-0.5,0.3-1.1,0.4-1.6c2.7,3.8,8.3,7.2,19.2,9.4C511.2,184.3,513.1,183.7,512.2,186.7z M537.3,178.6c-2.2-8.4-11.5-11.8-24.6-13.4c-5.5-0.7-8.6-0.9-7.9-3.3c0.5-1.8,3.3-2.2,9.2-2.2c3.7,0,8.1,0,13.2,0h15.6L537.3,178.6z M562.6,178.7c0-0.2,2.5-8.6,5.5-19h16.1l2.6,26.9C560.3,192.3,559.7,190.8,562.6,178.7z"
        />
        <path
            fill="#FFFFFF"
            d="M87.5,163.9L120.2,51h50.1l-10.1,35c-0.1,0.2-0.2,0.4-0.3,0.6L133.3,179h24.8c-10.4,25.9-18.5,46.2-24.3,60.9c-45.8-0.5-58.6-33.3-47.4-72.1 M133.9,240l60.4-86.9h-25.6l22.3-55.7c38.2,4,56.2,34.1,45.6,70.5C225.3,207,179.4,240,134.8,240C134.5,240,134.2,240,133.9,240z"
        />
    </SvgIcon>
)

export default BoostyIcon
